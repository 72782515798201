/*****************************
    Helper Classes
*****************************/
html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overscroll-behavior-y: none;

  -webkit-overflow-scrolling: auto !important;
 
}

body {
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: auto !important;
 
}

.text-primary {
  color: #F3601E !important;
}

.text-secondary {
  color: #121212 !important;
}

.text-white {
  color: #ffffff;
}

.text-dark {
  color: #121212 !important;
}

.text-light {
  color: #7c8087 !important;
}

.bg-primary {
  background: #F3601E !important;
}
.bg-grey {
  background-color: #F9F5F0;
}
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-secondary {
  background-color: #121212 !important;
}

.bg-overlay-secondary {
  position: relative;
  z-index: 1;
}

.bg-overlay-secondary:before {
  background: #f3601e;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-1 {
  position: relative;
  z-index: 1;
}

.testimonial {
  transition: background-color 0.3s ease;
}

.testimonial:hover {
  background-color: #F3601E;
}

.testimonial:hover .testimonial-content i {
  color: #ffffff;
}

.bg-overlay-black-1:before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-2 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-2:before {
  background: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-3 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-3:before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-4 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-4:before {
  background: rgba(0, 0, 0, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-5 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-5:before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-6 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-6:before {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-7 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-7:before {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-8 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-8:before {
  background: rgba(0, 0, 0, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-9 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-9:before {
  background: rgba(0, 0, 0, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light {
  position: relative;
  z-index: 1;
}

.bg-overlay-light:before {
  background: #f3f3f3;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.8;
}

.bg-overlay-primary-9 {
  position: relative;
  z-index: 1;
}

.bg-overlay-primary-9:before {
  background: #F3601E;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.9;
}


.space-ptb {
  padding: 80px 0;
}

.space-pt {
  padding: 80px 0 0;
}

.space-pb {
  padding: 0 0 80px;
}

.h-100vh {
  height: 100vh !important;
}

/* Sticky column */
.is-sticky {
  position: sticky;
  top: 100px;
}

.font-sm {
  font-size: 13px;
}


.video-btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #FF5722;
  margin: 0 auto 20px;
}

.video-btn-circle a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 24px;
}
.video-btn-circle a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FF5722;
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

.video-btn-circle a:hover,
.video-btn-circle a:focus,
.video-btn-circle a:active {
  background-color: #FF5722;
  color: #fff;
  text-decoration: none;
}



/* @media (min-width: 1200px) {
  .container {
    max-width: 1400px; } } */


.form-group {
  margin-bottom: 1rem;
}

@media (max-width: 1200px) {
  .space-ptb {
    padding: 80px 0;
  }

  .space-pt {
    padding: 80px 0 0;
  }

  .space-pb {
    padding: 0 0 80px;
  }
}

@media (max-width: 991px) {
  .space-ptb {
    padding: 60px 0;
  }

  .space-pt {
    padding: 60px 0 0;
  }

  .space-pb {
    padding: 0 0 60px;
  }
}

@media (max-width: 767px) {
  .space-ptb {
    padding: 50px 0;
  }

  .space-pt {
    padding: 50px 0 0;
  }

  .space-pb {
    padding: 0 0 50px;
  }
}

@media (max-width: 575px) {
  .space-ptb {
    padding: 40px 0;
  }

  .space-pt {
    padding: 40px 0 0;
  }

  .space-pb {
    padding: 0 0 40px;
  }
}
.nav-item {
  padding: 6px; /* Adjust padding as needed */
}

.nav-item:hover {
  background-color: #f8f9fa; /* Change to your desired hover background color */
}

.nav-item .nav-link {
  display: flex;
  align-items: center;
}

.nav-item .nav-link img {
  width: 16px;
  margin-right: 2px;
}

.nav-item .nav-link span {
  font-weight: 700;
  font-size: 14px;
  margin-left: 2px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff; /* Change to your desired dropdown background color */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 1000;
  margin-top: 0.125rem;
  padding: 10px; /* Adjust padding as needed */
}

.nav-item:hover .dropdown-menu {
  display: block;
}

/*****************************
    Typography
*****************************/
body {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 1.8;
  color: #7c8087;
  overflow-x: hidden;
}

.Poppins-fonts {
  font-family: 'Poppins', sans-serif;
}


a {
  outline: medium none !important;
  color: #F3601E;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}

a:focus {
  color: #F3601E;
  text-decoration: none !important;
}

a:hover {
  color: #F3601E;
  text-decoration: none !important;
}

input {
  outline: medium none !important;
  color: #F3601E;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #121212;
  margin-top: 0px;
  font-weight: 400;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

label {
  font-weight: normal;
}

h1 {
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
}

h2 {
  font-size: 34px;
  font-weight: 600;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 20px;
}

p {
  font-weight: normal;
  line-height: 1.8;
}

*::-moz-selection {
  background: #F3601E;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #F3601E;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #F3601E;
  color: #ffffff;
  text-shadow: none;
}

.fw-medium {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.border-radius {
  border-radius: 6px;
}

.border-radius-sm {
  border-radius: 4px;
}

.border-radius-md {
  border-radius: 10px;
}

.border-radius-lg {
  border-radius: 50%;
}

.box-shadow {
  -webkit-box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
  box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
}


/* form-control */
.form-control,
.form-select {
  border: 1px solid #ededed;
  height: 60px;
  padding: 10px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  font-size: 16px;
  color: #121212;
  background-clip: inherit !important;
}

.form-control:focus,
.form-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #F3601E;
}

.avatar-lg {
  min-width: 80px;
  width: 80px;
}



.form-control::-moz-placeholder {
  color: #7c8087;
}

.form-control::-ms-input-placeholder {
  color: #7c8087;
}

.form-control::-webkit-input-placeholder {
  color: #7c8087;
}

textarea.form-control {
  height: auto;
}




@media (max-width: 767px) {
  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }
}


/*****************************
  Button
*****************************/
.btn-link {
  text-decoration: underline !important;
  color: #121212;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-link:after {
  display: none;
  content: inherit;
}

.btn-link:hover {
  color: #F3601E;
}

button {
  outline: medium none !important;
  color: #F3601E;
}

/* btn */
.btn {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 15px 40px;
  overflow: hidden;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.show>.btn.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.show>.btn-primary.dropdown-toggle {
  background: #F3601E;
  border-color: #F3601E;
}

.btn.btn-sm {
  padding: 8px 20px;
  font-size: 14px;
}

.btn.btn-md {
  padding: 10px 30px;
  font-size: 14px;
}

.btn.btn-lg {
  padding: 20px 40px;
}

.btn.btn-xl {
  padding: 25px 40px;
}

.btn-app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.btn-app i {
  font-size: 40px;
}

.btn.btn-link {
  padding: 0;
  background-color: inherit;
  color: #121212;
  font-size: 14px;
}

.btn.btn-link:hover {
  color: #F3601E;
  text-decoration: underline !important;
}

.btn.btn-link:focus {
  color: #F3601E;
  text-decoration: underline !important;
}

.btn.btn-secondary {
  background: #121212;
  color: #ffffff;
}

.btn.btn-secondary:hover {
  color: #ffffff;
  background: #F3601E;
}

.btn.btn-secondary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
  background: #F3601E;
}

.btn.btn-secondary:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
  background: #F3601E;
}

.btn.btn-secondary:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #fc6f2d;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
}

.btn.btn-secondary:hover {
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn.btn-secondary:hover:after {
  left: 0;
  width: 100%;
}

.bg-primary .btn.btn-secondary {
  background: #121212;
  color: #ffffff;
}

.bg-primary .btn.btn-secondary:hover {
  color: #121212;
  background: #ffffff;
}

.bg-primary .btn.btn-secondary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #121212;
  background: #ffffff;
}

.bg-primary .btn.btn-secondary:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #121212;
  background: #ffffff;
}

.bg-primary .btn.btn-secondary:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #ffffff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
}

.bg-primary .btn.btn-secondary:hover {
  color: #121212;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.bg-primary .btn.btn-secondary:hover:after {
  left: 0;
  width: 100%;
}

.bg-secondary .btn.btn-primary {
  background: #F3601E;
  color: #ffffff;
}

.bg-secondary .btn.btn-primary:hover {
  color: #121212;
  background: #ffffff;
}

.bg-secondary .btn.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #121212;
  background: #ffffff;
}

.bg-secondary .btn.btn-primary:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #121212;
  background: #ffffff;
}

.bg-secondary .btn.btn-primary:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #ffffff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
}

.bg-secondary .btn.btn-primary:hover {
  color: #121212;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.bg-secondary .btn.btn-primary:hover:after {
  left: 0;
  width: 100%;
}

.btn.btn-primary {
  background: #F3601E;
  color: #ffffff;
}

.btn.btn-primary:hover {
  color: #ffffff;
  background: #F3601E;
}

.btn.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
  background: #F3601E;
}

.btn.btn-primary:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
  background: #F3601E;
}

.btn.btn-primary:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #f47339;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
}

.btn.btn-primary:hover {
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn.btn-primary:hover:after {
  left: 0;
  width: 100%;
}

.btn.btn-light {
  background: #ffffff;
  color: #121212;
}

.btn.btn-light:hover {
  color: #ffffff;
}

.btn.btn-light:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #121212;
  background: #ffffff;
}

.btn.btn-light:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #121212;
  background: #ffffff;
}

.btn.btn-light:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #F3601E;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btn.btn-light:hover {
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn.btn-light:hover:after {
  left: 0;
  width: 100%;
}

.btn.btn-gray {
  background: #b1b1b1;
  color: #fff;
}

.btn.btn-gray:hover {
  color: #ffffff;
}

.btn.btn-gray:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #fff;
  background: #b1b1b1;
}

.btn.btn-gray:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #fff;
  background: #b1b1b1;
}

.btn.btn-gray:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #F3601E;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btn.btn-gray:hover {
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn.btn-gray:hover:after {
  left: 0;
  width: 100%;
}



/*****************************
  Header
*****************************/
/* Header default */
.header {
  background: #fbfaf7;
  z-index: 999;
  position: relative;
  -webkit-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.02);
}

.header .topbar {
  background: #f3601e;
}

/* Topbar */
.header .topbar-inner {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(18, 18, 18, 0.05);
}

.header .topbar-left {
  display: flex;
  align-items: center;
}

.header .topbar-inner .topbar-left a {
  color: #fffefe;
}

.header .topbar-inner .topbar-left a img {
  margin-right: 5px;
}

.header .topbar-inner .topbar-left a:hover {
  color: #000000;
}

.header .topbar-inner .topbar-left a i {
  color: #fff;
}

.header .topbar-inner .topbar-left .topbar-call {
  margin-right: 24px;
}

.header .topbar-inner ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  color: #ffffff;
}

.header .topbar-inner ul li {
  padding: 0 10px;
}

.header .topbar-inner a {
  color: #ffffff;
  font-size: 13px;
  line-height: 1;
}
.dropdown-menu {
  display: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.header .topbar-inner a:hover {
  color: #000000;
}

.header .topbar-inner .topbar-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .topbar-inner .topbar-right .dropdown .dropdown-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .topbar .dropdown .dropdown-menu a {
  color: #121212;
  font-size: 13px;
  padding: 10px 20px 10px 0px;
}

.header .topbar .dropdown .dropdown-menu a:hover {
  color: #f3601e;
  background: rgba(243, 96, 30, 0.1);
  padding: 10px 20px 10px 10px;
  border-radius: 0px;
}

.header .topbar .dropdown .dropdown-menu {
  /* top: 50% !important; */
  padding: 10px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1);
  border: none;
}

.header .topbar-inner .topbar-right .dropdown .dropdown-menu .dropdown-item .country-flag {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.header .topbar-inner .topbar-divider {
  position: relative;
}

.header .topbar-inner .topbar-divider::after {
  position: absolute;
  content: "";
  height: 18px;
  width: 1px;
  right: 0;
  background: rgb(255, 255, 255);
  top: 50%;
  transform: translateY(-50%);
}

.header .topbar .dropdown .dropdown-toggle {
  font-size: 13px;
}

.header .topbar .dropdown .dropdown-toggle i {
  padding-left: 5px;
}

.header .topbar .dropdown .dropdown-toggle:after {
  display: none;
}

.header .topbar .dropdown .dropdown-toggle img {
  height: 30px;
  margin-right: 10px;
}


.header .navbar-toggler {
  display: none;
}
.header .navbar {
  padding: 0;
}

.header .navbar-brand {
  max-width: 220px;
}

.header .navbar .navbar-nav {
  position: relative;
}

.header .navbar .navbar-nav .nav-item {
  /* margin-right: 24px; */
}

.header .navbar .navbar-nav .nav-item .nav-link img {
  width: 20px;
  margin-right: 3px;
}


/* .header .navbar .navbar-nav .nav-item .nav-link .menu-icon svg{
        fill: #121212;
        width: 16px;
        height: 16px;
      }
      .header .navbar .navbar-nav .nav-item .nav-link:hover .menu-icon svg{
        fill: #F3601E;
      } */
.header .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.header .navbar .navbar-nav .nav-link {
  color: #121212;
  font-size: 14px;
  display: flex;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 14px 5px;
  align-items: center;
}

.header .navbar .navbar-nav .nav-link:hover {
  color: #F3601E;
}

.header .navbar .navbar-nav .nav-link i {
  margin-left: 8px;
}

.header .navbar .navbar-nav li.active>a {
  color: #F3601E;
}

.header-middel-container {
  align-items: center;
  justify-content: space-between;
}


.search-form {
  position: relative;
  flex: 1 1 auto;
  margin-left: 10px;
}

button.search-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: #7c8087;
  text-transform: uppercase;
  width: 46px;
}

.search-form .form-control {
  height: 42px;
  border-radius: 50px;
  width: 100%;
  min-width: 300px;
  padding-inline: 40px 15px;
  font-size: 14px;
}

.add-listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.account-action {
  margin-left: 20px;
}

.woo-action .woo-action-icon {
  margin-left: 20px;
}

.add-listing .account-icon {
  color: #121212;
  background-color: rgba(18, 18, 18, 0.05);
  padding: 0 13px;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 50px;
}

.add-listing .account-icon i {
  font-size: 20px;
}

.add-listing .account-icon span {
  font-size: 13px;
  padding-left: 5px;
}

.add-listing .account-icon:hover {
  color: #F3601E;
}

.add-listing .woo-action ul {
  margin: 0;
}

.woo-action .woo-action-icon>a,
.woo-action .woo-action-icon>button {
  color: #121212;
  background: rgba(18, 18, 18, 0.05);
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: none;
}

.woo-action .woo-action-icon>a:hover,
.woo-action .woo-action-icon>button:hover {
  color: #F3601E;
}

.add-listing .woo-action .cart .cart-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: 0px;
  width: 16px;
  height: 16px;
  font-size: 10px;
  color: #ffffff;
  background: #121212;
  border-radius: 50%;
}

.add-listing .woo-action .cart .dropdown-toggle {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.add-listing .woo-action .cart .dropdown-toggle object {
  width: 23px;
  -webkit-filter: invert(10%) sepia(8%) saturate(5323%) hue-rotate(164deg) brightness(98%) contrast(92%);
  filter: invert(10%) sepia(8%) saturate(5323%) hue-rotate(164deg) brightness(98%) contrast(92%);
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.add-listing .woo-action .cart .dropdown-toggle:hover {
  color: #F3601E;
}

.add-listing .woo-action .cart .dropdown-toggle:hover object {
  -webkit-filter: invert(84%) sepia(25%) saturate(1301%) hue-rotate(134deg) brightness(90%) contrast(84%);
  filter: invert(84%) sepia(25%) saturate(1301%) hue-rotate(134deg) brightness(90%) contrast(84%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.add-listing .woo-action .cart .dropdown-toggle:after {
  border-top: none;
  content: unset;
}

.add-listing .woo-action .cart .dropdown-menu-right {
  background-color: #ffffff;
  position: absolute;
  -webkit-box-shadow: 0 1rem 3rem rgba(18, 18, 18, 0.175);
  box-shadow: 0 1rem 3rem rgba(18, 18, 18, 0.175);
  border-radius: 6px;
  width: 315px;
  z-index: 9;
  -webkit-transform: translate3d(0%, 10px, 0);
  transform: translate3d(0%, 10px, 0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, visibility, transform;
  transition-property: opacity, visibility, transform, -webkit-transform;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  visibility: hidden;
  opacity: 0;
  display: block !important;
  right: 0;
}

.add-listing .woo-action .cart .dropdown-menu-right li {
  margin-right: 0;
  padding: 15px 40px 15px 15px;
  border-bottom: 1px solid #ededed;
  position: relative;
}

.add-listing .woo-action .cart .dropdown-menu-right a {
  color: #121212;
}

.add-listing .woo-action .cart .dropdown-menu-right a:hover {
  color: #F3601E;
}

.add-listing .woo-action .cart .dropdown-menu-right li .cart-info a {
  font-size: 14px;
  font-weight: 500;
}

.add-listing .woo-action .cart .dropdown-menu-right li .remove-item {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 12px;
}

.add-listing .woo-action .cart .dropdown-menu-right li img {
  width: 80px;
  height: auto;
}

.add-listing .woo-action .cart .cart-footer {
  padding: 15px;
}

.add-listing .woo-action .cart .cart-footer a {
  display: inline-block;
  text-align: center;
}

.add-listing .woo-action .cart .cart-footer a.btn {
  color: #ffffff;
}

.add-listing .woo-action .cart:hover .dropdown-menu-right {
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  visibility: visible;
  opacity: 1;
}

.free-shipping a {
  padding: 5px 10px;
  min-height: 28px;
  font-size: 12px;
  color: #121212;
  background: #e0eaf0;
  border-radius: 20px;
  display: inline-block;
  font-weight: 500;
}

.free-shipping a:hover {
  color: #F3601E;
}

.header.is-sticky {
  position: fixed !important;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
  box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
  top: 0;
  z-index: 999;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.header.is-sticky .topbar {
  display: none !important;
}

@-webkit-keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  min-width: 160px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.mobile-foot-menu ul {
  padding: 15px 26px;
}
.mobile-foot-menu li a {
  color: #121212;
  font-weight: 700;
}
.mobile-foot-menu li a:hover {
  color: #F3601E;
}

/*Banner 02*/
.banner-section-wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.banner-02 .slider-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  z-index: 2;
  top: 50%;
  bottom: 0;
  left: 20px;
  padding-top: 87px;
  text-align: center;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}


.banner-02 .swiper-slide {
  height: 600px;
  padding-top: 80px;
}

.banner-02 .swiper-slide .title {
  font-size: 80px;
  line-height: 1;
}

.banner-02 .swiper-slide p {
  font-size: 20px;
  line-height: 28px;
  margin: 20px 0 35px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.banner-02 .pagination-button .swiper-button-prev {
  background-image: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 50px;
  top: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.banner-02 .pagination-button .swiper-button-prev:hover {
  color: #F3601E;
}

.banner-02 .pagination-button .swiper-button-next {
  background-image: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 50px;
  top: auto;
  right: auto;
  left: 80px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.banner-02 .pagination-button .swiper-button-next:hover {
  color: #F3601E;
}


/*** section Title **/
.section-title {
  margin-bottom: 20px;
  position: relative;
}

.section-title.section-title-style-1 .sub-title.left-divider {
  position: relative;
  padding-left: 55px;
}

.section-title.section-title-style-1 .sub-title.left-divider::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background: #F3601E;
}

.section-title.section-title-style-1 .sub-title {
  color: #F3601E;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.section-title h2,
.section-title h1 {
  margin-bottom: 15px;
  font-weight: 600;
}

.section-title .storke-title {
  font-size: 74px;
  font-weight: 600;
  color: rgba(18, 18, 18, 0.05);
  position: absolute;
  top: -20px;
  line-height: 1;
  display: flex;
  justify-content: center;
  width: 100%;
}

/***** categories ***/

.feature-categories-wrapper {
  display: flex;
  margin: 0 -15px;
}

.featured-categories-column {
  flex: 0 0 auto;
  width: 20%;
  padding: 0 15px;
}

.featured-categories-column .categories-title {
  font-size: 18px;
}

.feature-categories-inner {
  position: relative;
}

.categories-product {
  height: 90%;
  width: 90%;
  background: #fff;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 10px solid rgba(0, 0, 0, 0.1);
}

.feature-categories-inner:hover .categories-product {
  opacity: 0.92;
  color: #121212;
}

.categories-img {
  display: block;
}

.categories-img img {
  transition: all .5s ease;
  width: 100%;
  border-radius: 50%;
}


/************
     Quickview modal
*************/

.quickview-modal .product-detail .input-group {
  float: left;
  margin-right: 20px;
  width: 90px;
}

.quickview-modal .product-detail .product-price-rating .product-price h4 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}

.quickview-modal .product-detail .product-price-rating .product-price h4 del {
  color: #7c8087;
}

.quickview-modal .product-detail .product-price-rating .product-price h4 span {
  color: #F3601E;
  margin-left: 10px;
}

.quickview-modal .product-detail .product-detail-meta {
  margin-bottom: 35px;
}

.quickview-modal .product-detail .product-detail-meta span {
  display: block;
  margin: 15px 0;
}

/******************************
         furniture
*******************************/
.furniture-collection {
  position: relative;
}

.furniture-collection .furniture-img {
  position: relative;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  cursor: pointer;
}


.furniture-collection:hover .furniture-img::after {
  opacity: 1;
}

.furniture-collection .furniture-img::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(15, 15, 15, 0.65)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.65) 100%);
  -webkit-transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}

.furniture-collection .furniture-info {
  width: calc(100% - 30px);
  position: absolute;
  left: 15px;
  padding: 15px;
  bottom: 15px;
  z-index: 2;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.furniture-collection:hover .furniture-info {
  bottom: 20px;
}

.furniture-collection .furniture-img img {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.0);
  -moz-transform: scale(1.0);
  transform: scale(1.0);
}

.furniture-collection:hover .furniture-img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

/*****************************
  Owl carousel
*****************************/
.owl-carousel .owl-item img {
  width: auto;
  display: inherit;
}

.owl-carousel .owl-nav button {
  background-color: #ffffff !important;
  padding: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(18, 18, 18, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(18, 18, 18, 0.1);
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-nav button i {
  color: #121212;
}

.owl-carousel .owl-nav button:hover i {
  color: #ffffff;
}

.owl-carousel .owl-nav button:before {
  content: '';
  background-color: #F3601E !important;
  width: 100%;
  height: 100%;
  -webkit-transition: all 490ms ease;
  transition: all 490ms ease;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.owl-carousel .owl-nav button:hover {
  background-color: #F3601E !important;
}

.owl-nav .owl-prev{
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  font-size: 50px !important;
}
.owl-nav .owl-next {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  font-size: 50px !important;
}

.owl-carousel .owl-nav button:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.owl-nav {
  visibility: visible !important;
  opacity: 1 !important;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  opacity: 1 !important;
}


.owl-prev:hover,
.owl-next:hover {
  background-color: transparent !important;
}



.owl-carousel.owl-nav-center .owl-nav {
  width: 100%;
}

.owl-carousel.owl-nav-center .owl-nav .owl-next {
  top: 50%;
  right: -30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.owl-carousel.owl-nav-center .owl-nav .owl-prev {
  top: 50%;
  left: -30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-nav .owl-next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0;
}


.categories-section .owl-carousel .owl-nav .owl-next {
  right: 0;
}

.categories-section .owl-carousel .owl-nav .owl-prev {
  left: 0;
}

.categories-section .owl-carousel .owl-nav button {
  opacity: 0;
}

.categories-section .owl-carousel:hover .owl-nav button {
  opacity: 1;
}

.categories-section .owl-carousel:hover .owl-nav .owl-next {
  right: -30px;
}

.categories-section .owl-carousel:hover .owl-nav .owl-prev {
  left: -30px;
}

@media (max-width: 991px) {
  .owl-carousel .owl-dots {
    margin-top: 30px;
  }

  .perfect-tour .owl-nav {
    display: none;
  }
}

@media (max-width: 767px) {
  .owl-carousel .owl-dots {
    margin-top: 20px;
  }

  .owl-carousel.owl-nav-center .owl-nav .owl-prev {
    display: none;
  }

  .owl-carousel.owl-nav-center .owl-nav .owl-next {
    display: none;
  }
}

/*** banner ****/

.banner-content h5 {
  /* position: absolute;
  right: 0; */
  font-size: 60px;
  font-weight: 600;
  color: #F3601E;
  line-height: 1;
  top: -10px;
  opacity: 0.5;
}

.banner-content h2 {
  font-size: 34px;
  font-weight: 700;
}

/*****************************
 Product
*****************************/
.product {
  position: relative;
}

.product-label {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.product-label span {
  height: 20px;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 70px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
}

.product-label .onsale {
  padding: 0;
  background: #ff5500;
  width: 40px;
  height: 70px;
  border-radius: 50%;
  color: #F3F3F3;
  display: flex;
  justify-content: center;
  transform: scale(0.7, 0.7);
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}


.product-label .featured {
  color: #fff;
  background: rgb(247, 148, 29);
}

.product .product-image {
  position: relative;
}

.product .product-image .product-thumb-inner {
  overflow: hidden;
}

.product .product-image .product-thumb-inner img {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.0);
  -moz-transform: scale(1.0);
  transform: scale(1.0);
}

.product:hover .product-image .product-thumb-inner img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.product .product-image .custom-icon {
  position: absolute;
  top: 20px;
  opacity: 0;
  right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product .product-image .custom-icon ul li a {
  font-size: 16px;
  color: #121212;
  display: block;
  line-height: 42px;
  background: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product .product-image .custom-icon ul li:hover a {
  color: #ffffff;
  background: #F3601E;
}

.product .product-content {
  padding: 20px 0px 30px;
}

.product .product-content .product-info .product-title h3 {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 16px;
}

.product .product-content .product-info .product-star ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product .product-content .product-info .product-star ul li i {
  color: #ee9914;
  font-size: 14px;
}

.product .product-content .category {
  font-size: 18px;
  font-weight: 500;
  color: #121212;
}

.product .product-content .category:hover {
  color: #F3601E;
}

.product .product-content .product-prize {
  padding-top: 5px;
}

.product .product-content .product-prize p {
  color: #121212;
  margin-bottom: 0;
}

.product .product-content .product-prize p span {
  color: rgba(18, 18, 18, 0.6)
}

.product:hover .custom-icon {
  opacity: 1;
  right: 20px;
}

.product-btn {
  position: absolute;
  bottom: -20px;
  opacity: 0;
  right: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  left: 0;
  padding: 20px;
}

.product:hover .product-btn {
  opacity: 1;
  bottom: 0;
}

/*Testimonial style 01*/
.testimonial {
  position: relative;
  padding-top: 50px;
}

.testimonial .testimonial-star-icon ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 20px;
}

.testimonial .testimonial-star-icon ul li {
  list-style-type: none;
  margin-right: 5px;
  font-size: 16px;
}

.testimonial .testimonial-quote-icon {
  width: 105px;
  position: absolute;
  left: 25px;
  top: 5px;
  opacity: 1;
  z-index: -1;
}

.testimonial .testimonial-author {
  margin-top: 10px;
  display: inline-block;
}

.testimonial .testimonial-author .avatar img {
  width: 55px;
  border-radius: 5px;
}

.testimonial .testimonial-author .testimonial-name {
  padding-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonial .testimonial-author .testimonial-name .author-tittle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

.testimonial .testimonial-author .testimonial-name span {
  position: relative;
  padding-left: 15px;
  margin-left: 15px;
  font-size: 14px;
}

.testimonial .testimonial-author .testimonial-name span:before {
  content: "";
  height: 1px;
  width: 5px;
  background: #121212;
  position: absolute;
  left: 0;
  top: 50%;
}


.testimonial .testimonial-content p {
  font-size: 22px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.testimonial-dark-bg .testimonial-description {
  
  position: relative;
  overflow: hidden;
}
.testimonial-dark-bg .testimonial-description .testimonial {
  background-color: #F3601E;
  padding: 30px;
}
.testimonial-dark-bg .testimonial-description .testimonial .testimonial-content p {
  color: #ffffff;
  font-size: 18px;
  line-height: 38px;
}

.testimonial-dark-bg .testimonial-description .testimonial .testimonial-author .testimonial-name h6 {
  color: #ffffff;
  font-weight: 600;
}

.testimonial-dark-bg .testimonial-description .testimonial .testimonial-author .testimonial-name span {
  color: #F3601E;
}

.testimonial-dark-bg .testimonial-description .testimonial .testimonial-author .testimonial-name span:before {
  background: #ffffff;
}

.testimonial-dark-bg .testimonial-description .testimonial .testimonial-quote-icon {
  opacity: 0.2;
}

.testimonial-dark-bg .testimonial-description .owl-nav button i {
  color: #ffffff;
}

.testimonial-dark-bg .testimonial-description:before {
  content: "";
  background: url(../images/testimonial/quote.svg);
  position: absolute;
  top: 100px;
  left: inherit;
  right: -50px;
  width: 450px;
  height: 100%;
  background-position: right;
  background-repeat: no-repeat;
  opacity: 0.04;
  z-index: 9;
}

.mandir-bg {
  background: url(../images/bg/mandir-banner.jpg) no-repeat center center;
  background-size: cover;
}

.testimonial-dark-bg .owl-nav {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.testimonial-dark-bg .owl-nav button {
  background-color: #fff !important;
  color: #121212;
  /* box-shadow: none; */
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px !important;
  width: 60px !important;
}

.testimonial-dark-bg .owl-nav .owl-prev {
  margin-right: 15px !important;
}

.testimonial-dark-bg .owl-nav .owl-prev i {
  font-size: 24px;
  color: #121212;
}

.testimonial-dark-bg .owl-nav .owl-next i {
  font-size: 24px;
  color: #121212;
}


/********* Blog ******/

.blog-section {
  padding-top: 170px;
}

.blog-post .blog-info {
  padding-top: 25px;
}

.blog-post .blog-info p {
  margin-bottom: 10px;
}

.blog-post .blog-info h4 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
}

.blog-post .blog-info a.blog-link {
  font-weight: 500;
}

.blog-post .blog-info span {
  font-weight: 500;
  padding-bottom: 10px;
  display: inline-block;
}

.blog-post .blog-info .blog-comment span {
  position: relative;
  color: #555555;
}

.blog-post .blog-info .blog-comment span:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 3px;
  background: #555555;
  right: 16px;
  top: 12px;
}

.blog-post .blog-info .blog-comment a {
  font-weight: 500;
  color: #555555;
}

.blog-post .blog-info .blog-comment a:hover {
  color: #F3601E;
}

.blog-post .blog-info .blog-link:hover {
  color: #121212;
}



/******************
      Footer
******************/


.footer .footer-logo {
  margin-bottom: 30px;
}

.footer .footer-logo img {
  max-width: 250px;
}

.footer p {
  color: #fff;
}

.footer .footer-contact-info {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.footer .footer-contact-info i {
  margin-right: 20px;
  color: #F3601E;
  font-size: 30px;
}

.footer .useful-links li {
  margin-bottom: 10px;
}

.footer .useful-links li a {
  color: #fff;
}

.footer .useful-links li a:hover {
  color: #F3601E;
}

.footer .copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 30px 0;
  margin-top: 40px;
}

.footer .payment-img img {
  max-width: 320px;
}

.footer .copyright a:hover {
  color: #F3601E !important;
}

.footer-newsletter .form-check-label {
  padding-left: 0;
}

/*** back to top ***/

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 20px;
  color: #ffffff;
  background-color: #F3601E;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  cursor: pointer;
  -webkit-box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
  box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
  -webkit-transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  z-index: 99;
}

.back-to-top:hover {
  color: rgba(255, 255, 255, 0);
  -webkit-transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
}

.back-to-top:hover:before {
  -webkit-animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.back-to-top:hover:after {
  -webkit-animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.back-to-top:before {
  position: absolute;
  display: inline-block;
  content: "";
  background: #ffffff;
  width: 3px;
  height: 20px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -2px;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

.back-to-top:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 15px;
  height: 15px;
  color: #ffffff;
  border-top: 3px solid;
  border-left: 3px solid;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -8px;
  -webkit-transform: translateY(50px) rotateZ(45deg);
  transform: translateY(50px) rotateZ(45deg);
}

.back-to-top a {
  color: #ffffff;
}

.back-to-top:hover a {
  color: transparent;
}

.back-to-top.auto {
  color: rgba(255, 255, 255, 0);
  -webkit-transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
}

.back-to-top.auto:before {
  -webkit-animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

.back-to-top.auto:after {
  -webkit-animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}

@-webkit-keyframes tipUp {
  0% {
    -webkit-transform: translateY(50px) rotateZ(45deg);
    transform: translateY(50px) rotateZ(45deg);
  }

  100% {
    -webkit-transform: translateY(-70px) rotateZ(45deg);
    transform: translateY(-70px) rotateZ(45deg);
  }
}

@keyframes tipUp {
  0% {
    -webkit-transform: translateY(50px) rotateZ(45deg);
    transform: translateY(50px) rotateZ(45deg);
  }

  100% {
    -webkit-transform: translateY(-70px) rotateZ(45deg);
    transform: translateY(-70px) rotateZ(45deg);
  }
}

@-webkit-keyframes lineUp {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}

@keyframes lineUp {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}


/*Inner Header*/

.header-inner {
  padding: 20px 0 5px;
}

.header-inner .section-title {
  margin-bottom: 0;
}

.header-inner-menu {
  background-position: center;
  background-size: cover;
}

.header-inner-menu .header-inner-title {
  margin: 0px 4px;
}

.header-inner-menu .header-inner-title .section-title .sub-title span {
  text-transform: capitalize;
  color: #ffffff;
}

.header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  color: #ffffff;
}

.header-inner-menu .header-inner-title .title {
  font-size: 40px;
}

.header-inner-shape {
  padding: 22px;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
}

.perfect-tour .title-border {
  border-bottom: 1px solid #ededed;
  padding-bottom: 40px;
}

.contant-404 h2 {
  font-size: 59px;
  font-weight: bold;
}

.instagram-icon i {
  font-size: 65px;
}

.breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb{
  margin-bottom: 0rem;
}



/*****************************
Select
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0px;
}

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #f3f9ff;
  font-weight: normal;
  line-height: inherit;
  text-transform: capitalize;
}

.bigdrop {
  min-width: 196px !important;
}

.select2-results__options {
  padding: 8px 2px;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="radio"]:radio {
  background-color: #F3601E;
}

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 14px;
  right: 10px;
  height: 21px;
  width: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  color: #7c8087;
}

.select2-container--default .select2-search--dropdown {
  padding: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #ededed;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f3f9ff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(61, 209, 213, 0.1);
  color: #F3601E;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 9;
}

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #ededed;
  height: 50px;
  line-height: 20px;
  padding: 15px 20px;
  border-radius: 0px;
}

.select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 16px;
  right: 20px;
}

.select2-results__option[aria-selected] {
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.select2-container--default .select2-results>.select2-results__options {
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #7c8087;
}

.select2-container--open .select2-dropdown {
  background: #ffffff;
  padding: 7px;
  border: 1px solid rgba(61, 209, 213, 0.1);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(18, 18, 18, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(18, 18, 18, 0.1);
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ededed;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
}

.select2-container--default .select2-selection--single {
  height: 50px;
  padding: 15px 20px;
}

.custom-control-input:radio {
  background-color: #F3601E;
}

.newsletter .search input::-webkit-input-placeholder {
  color: #ffffff;
}

.newsletter .search input::-moz-placeholder {
  color: #ffffff;
}

.newsletter .search input:-ms-input-placeholder {
  color: #ffffff;
}

.newsletter .search input::-ms-input-placeholder {
  color: #ffffff;
}

.newsletter .search input::placeholder {
  color: #ffffff;
}

.form-check .form-check-input {
  float: inherit;
}

.form-check-input:checked {
  background-color: #F3601E;
  border-color: #F3601E;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.search-field {
  padding-left: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  height: 50px;
  border-radius: 0px;
  border: 1px solid #ededed;
  width: 100%;
  background: #ffffff;
}

/*****************************
  sidebar
*****************************/
.sidebar .widget {
  margin-top: 0px !important;
  margin-bottom: 40px;
}

.sidebar .widget .widget-title {
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
}

.sidebar .widget .widget-title .title,
.widget-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}

.sidebar .widget .search {
  position: relative;
}

.sidebar .widget .search i {
  position: absolute;
  right: 0;
  top: 0;
  background: #F3601E;
  color: #ffffff;
  padding: 17px 20px;
  cursor: pointer;
  margin: 0;
  border-radius: 0 6px 6px 0px;
}

.sidebar .widget .search input {
  padding-right: 64px;
  height: 50px;
  padding-left: 20px;
}

.sidebar .widget .social ul li a {
  color: #121212;
  font-weight: 600;
  text-transform: capitalize;
}

.sidebar .widget .price-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidebar .widget .price-filter a {
  margin-left: auto;
}

.sidebar .widget .product-rating {
  margin-bottom: 10px;
}

.sidebar .widget .product-rating:last-child {
  margin-bottom: 0;
}

.sidebar .widget .product-rating .product-rating-nav {
  display: inline-block;
}

.sidebar .widget .product-rating span {
  float: right;
}

.sidebar .widget .product-banner-thumb {
  position: relative;
}

.sidebar .widget .product-banner-thumb .product-banner-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background: #121212;
  color: #ffffff;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidebar .widget .product-banner-thumb .product-banner-badge .badge-value {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}

.sidebar .widget .form-check {
  display: block;
}

.sidebar .widget .form-check span {
  float: right;
}

.sidebar .widget .social ul {
  margin-bottom: 0;
}

.sidebar .widget .social ul li {
  display: inline-block;
  list-style-type: none;
  font-size: 16px;
  border-radius: 4px;
}

.sidebar .widget .tagcloud ul li {
  margin-bottom: 10px;
  display: inline-block;
}

.sidebar .widget .tagcloud ul li a {
  line-height: 32px;
  border: 1px solid #ededed;
  padding: 0 12px;
  border-radius: 6px;
  display: block;
}

.sidebar .widget .tagcloud ul li a:hover {
  background: #F3601E;
  border: 1px solid #F3601E;
  color: #ffffff;
}

.sidebar .widget .widget-categories ul li a {
  font-size: 14px;
  line-height: 32px;
  display: block;
}

.sidebar .widget .widget-categories ul li a:hover {
  color: #121212;
}

.sidebar .widget .search {
  position: relative;
}

.sidebar .widget .search i {
  position: absolute;
  right: 0;
  top: 0;
  background: #F3601E;
  color: #ffffff;
  padding: 17px 20px;
  cursor: pointer;
  margin: 0;
  border-radius: 0px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidebar .widget .search i:hover {
  background: #121212;
}

.sidebar .widget .search input {
  padding-right: 64px;
  height: 50px;
  padding-left: 20px;
}

.sidebar .widget ul.list-style li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #7c8087;
  width: 100%;
}

.sidebar .widget .widget-categories .list-style-underline li a {
  padding: 5px 0;
}

.sidebar .widget .widget-categories .list-style-underline li a span {
  font-size: 10px;
}

.sidebar .widget .widget-categories .list-style-underline li:last-child a {
  border-bottom: inherit;
}

.sidebar .widget .widget-categories .count {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #f3f3f3;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
}

.sidebar .widget .widget-color ul li a:hover {
  color: #121212;
}

.sidebar .widget .popular-brand ul li {
  display: inline-block;
  margin-bottom: 10px;
}

.sidebar .widget .popular-brand ul li:last-child {
  margin-bottom: 0;
}

.sidebar .widget .popular-brand ul li a {
  padding: 8px 20px;
  display: block;
  font-size: 14px;
  border: 1px solid #ededed;
  color: #7c8087;
  border-radius: 0px;
}

.sidebar .widget .popular-brand ul li a:hover {
  color: #F3601E;
  border-color: #F3601E;
}

.sidebar .widget .widget-color ul li a {
  display: flex;
  align-items: center;
}

.sidebar .widget .widget-color .filter-color {
  width: 25px !important;
  height: 25px !important;
  overflow: visible;
  border: none !important;
  position: relative;
  -webkit-transition: transform .1s ease;
  transition: transform .1s ease;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 0 !important;
  min-width: unset;
  margin-right: 10px;
}

.sidebar .widget .widget-color .filter-color:before {
  content: "\f26e";
  position: absolute;
  font-family: bootstrap-icons !important;
  color: #fff;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.sidebar .widget .widget-color ul li a:hover .filter-color:before {
  opacity: 1;
}

.sidebar .widget .widget-color .filter-color input {
  display: none;
}

.sidebar .widget .widget-color ul li a {
  font-size: 14px;
  line-height: 32px;
  display: block;
  padding: 5px 0;
}

.sidebar .widget .widget-color .count {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #f3f3f3;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
}

.sidebar .widget .widget-product .product-image {
  flex: 0 0 80px;
  margin-right: 15px;
}

.sidebar .widget .widget-product a,
.sidebar .widget .widget-product p {
  font-size: 14px;
}

/*****************************
  Range Slider
*****************************/
.irs--flat .irs-line {
  height: 2px;
  border-radius: 0px;
}

.irs--flat .irs-bar {
  background: #121212;
  height: 2px;
}

.irs--flat .irs-from {
  background: transparent;
  color: #121212;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-from:before {
  display: none;
}

.irs--flat .irs-to {
  background: transparent;
  color: #121212;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-to:before {
  display: none;
}

.irs--flat .irs-single {
  background: transparent;
  color: #121212;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-single:before {
  display: none;
}

.irs--flat .irs-handle {
  top: 20px;
}

.irs--flat .irs-handle>i:first-child {
  background: #ffffff;
  background: #ffffff;
  width: 12px;
  width: 12px;
  height: 12px;
  height: 12px;
  border-radius: 0;
  border: 2px solid #121212;
  cursor: pointer;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.irs--flat .irs-handle:hover>i:first-child {
  background: #121212;
}

.irs--flat .irs-handle.state_hover>i:first-child {
  background: #121212;
}

/********************** 
login register form
**********************/

.login-register-modal form {
  padding: 0px 10px 10px;
  position: relative;
}

/* .login-register-modal .form-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  font-size: 18px;
  margin: 0 0 30px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 0 30px 15px;
  background: #fff;
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
} */

.login-register-modal .modal-header {
  padding: 15px;
  border-radius: 0;
  border-bottom: 0px;
}

.login-register-modal .modal-content {
  border-radius: 0px;
  border: 0px;
}

.login-register-modal .form-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.login-register-modal .modal-body {
  background-color: #fff;
  padding: 30px;
  overflow-x: inherit;
}

.login-register-modal .box-content {
  position: relative;
  /* border: 1px solid #ededed; */
}

.login-register-modal .form-login {
  position: relative;
  z-index: 3;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.login-register-modal .form-login.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.login-register-modal .form-register {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.login-register-modal .form-register.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.login-register-modal .back-to-login {
  color: #121212;
}
.login-register-modal .back-to-login:hover {
  color: #F3601E;
}

/*********************
 Cart 
 *********************/
.cart-table {
  border: 1px solid #ededed;
}

.cart-table .table {
  margin-bottom: 0;
  font-size: 16px;
}

.cart-table .table tr th {
  border: none;
  padding: 16px 30px;
  color: #121212;
  background-color: #F3F3F3;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
}

.cart-table .table tr td {
  border: none;
  border-bottom: 1px solid #ededed;
  padding: 20px 30px;
  font-weight: 400;
  text-align: left;
  vertical-align: middle;
}

.cart-table .table tr td.product-name {
  font-weight: 500;
}

.cart-table .table tr td.product-remove {
  padding: 20px 30px;
  text-align: center;
}

.cart-table .table tr td.product-remove a {
  color: #121212;
  font-size: 18px;
}

.cart-table .table tr td.product-quantity .form-control {
  width: 100px;
}

.cart-table .table tr td.product-stock {
  color: #61d009;
}

/* .cart-table .table tr td a:hover {
      color: #F3601E; }
    .cart-table .table tr td .btn-primary:hover {
      color: #ffffff; } */
.cart-table .table thead tr {
  border: none;
}

.cart-table .table thead tr th:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cart-table .table thead tr th:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.cart-table .table tbody tr td.product-name a {
  color: #121212;
}

.cart-table .table tbody tr td.product-name a:hover {
  color: #F3601E;
}

.cart-table .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: none;
  padding: 30px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-table .actions .coupon form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cart-table .actions .coupon .input-text {
  border: 1px solid #ededed;
  border-radius: 0px;
  padding: 10px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-right: 20px;
}

.cart-table .actions .update-cart {
  margin-left: auto;
}

.cart-table .table td.product-thumbnail img {
  width: 100px;
  height: auto;
}

.cart-total-sidebar {
  border: 1px solid #ededed;
}

.cart-totals {
  padding: 20px;
}

.cart-totals .cart-totals-title {
  margin-bottom: 20px;
}

.cart-totals .table {
  font-size: 14px;
}

.cart-totals .table tr th {
  text-align: left;
  border: none;
  border-bottom: 1px solid #ededed;
  vertical-align: top;
  background: #ffffff;
}

.cart-totals .table tr td {
  text-align: right;
  border: none;
  border-top: 1px solid #ededed;
  background: #ffffff;
}

.cart-totals .table tr.shipping th {
  font-weight: 400;
  font-size: 16px;
}

.cart-totals .table tr.shipping .form-check-label {
  padding-left: 0px;
}

.cart-totals .table tr.shipping p {
  margin-bottom: 0;
}

.cart-totals .table tr.order-total th {
  font-size: 18px;
  font-weight: 500;
  color: #121212;
}

.cart-totals .table tr.order-total td {
  font-size: 18px;
  font-weight: 500;
  color: #F3601E;
}

.cart-totals .table .cart-subtotal th {
  font-size: 16px;
  font-weight: 400;
  border: none;
}

.cart-totals .table .cart-subtotal td {
  font-size: 16px;
  font-weight: 400;
  border: none;
}

.cart-totals .checkout-button {
  width: 100%;
  text-align: center;
}

/*commentlist*/
.commentlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.commentlist .comment-author {
  width: 70px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
}

.commentlist .comment-author img {
  border-radius: 100%;
  border: 4px solid #ffffff;
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}

.commentlist .comment-content {
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 100%;
  background: #f8f9fa;
  border: 1px solid #ededed;
}

.commentlist .comment-content .reviews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.commentlist .comment-content .reviews .rating {
  margin-left: auto;
}

.commentlist .comment-content .reviews .rating i {
  color: #F3601E;
}

.form-check-label {
  padding-left: 15px;
}

.search-field {
  padding-left: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ededed;
  width: 100%;
  background: #ffffff;
}

/* Checkout */
.checkout.checkout-form {
  border: 1px solid #ededed;
  padding: 30px;
}

.checkout-info {
  padding: 25px 25px 25px 25px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ededed;
}

.checkout-info .returning-icon {
  -webkit-box-flex: 0;
  margin-right: 15px;
  color: #121212;
}

.checkout-info-coupon .checkout-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkout-info-coupon .checkout-info .coupon-icon {
  -webkit-box-flex: 0;
  margin-right: 15px;
  color: #121212;
}

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #ededed;
  border-radius: 4px;
}

.checkout-review {
  padding: 15px 30px 30px;
  border: 1px solid #ededed;
}

.checkout-review .table tr th {
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  color: #121212;
}

.checkout-review .table tr th.shipping {
  font-size: 14px;
}

.checkout-review .table tr td {
  text-align: left;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;
  color: #7c8087;
}

.checkout-review .table tr td.amount {
  font-size: 18px;
}

.checkout-review .table tbody {
  border-bottom: 2px solid #ededed;
}

/***********
      footer top newsletter
**********/
.footer .footer-top {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 40px;
}
.newsletter form .btn {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 22;
}


/*****************************
 Accordion
*****************************/
.accordion-body {
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}

.accordion .accordion-item {
  border: none;
  margin-bottom: 20px;
}

.accordion .accordion-item .accordion-button {
  padding: 18px 30px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  background-color: #f3f3f3;
}

.accordion .accordion-item .accordion-button:after {
  display: none;
}

.accordion .accordion-item .accordion-button i {
  position: absolute;
  right: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #F3601E;
  color: #ffffff;
}

.accordion .accordion-item .accordion-button:not(.collapsed):after {
  display: none;
}

.accordion .accordion-item .accordion-button:not(.collapsed) i {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion .card-header {
  background: none;
  border-bottom: none;
  padding: 0;
  border-radius: inherit;
}

.accordion .card-header .accordion-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.accordion .card-header .accordion-title .btn {
  background: #F3601E;
  padding: 18px 30px;
}

.accordion .card-header .accordion-title i {
  font-size: 12px;
  margin-left: auto;
}

.accordion-checkbox .card label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.accordion-checkbox .card label .accordion-title {
  padding-left: 11px;
  display: inline-block;
}

.accordion-checkbox .card .card-body {
  padding: 10px 0px 0px 36px;
}

.accordion-checkbox .card .pay-icon {
  height: 20px;
  margin-left: auto;
}


/* Product Detail */

.shop-single .nav-tabs-02 {
  border: inherit;
}

.shop-single .nav-tabs-02 .nav-item {
  padding-right: 40px;
}

.shop-single .nav-tabs-02 .nav-item .nav-link {
  font-weight: 500;
  padding: 10px 0;
  text-transform: uppercase;
  border: inherit;
  color: #121212;
}

.shop-single .nav-tabs-02 .nav-item .nav-link.active {
  color: #F3601E;
  border-bottom: 2px solid;
  font-weight: 500;
}

.shop-single .nav-tabs-02 .tab-content {
  padding: 30px;
  border: 1px solid #ededed;
}

.shop-single .nav-tabs-02 .tab-content .feature-info-inner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-single .nav-tabs-02 .tab-content .feature-info-icon {
  -webkit-box-flex: initial;
  -ms-flex: initial;
  flex: initial;
  margin-right: 20px;
}

.shop-single .nav-tabs-02 .tab-content .feature-info-icon i {
  font-size: 60px;
  line-height: 60px;
}

.shop-single .product-detail .product-price-rating {
  padding-bottom: 30px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}

.shop-single .product-detail .product-price-rating .product-price h4 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}

.shop-single .product-detail .product-price-rating .product-price h4 del {
  color: #7c8087;
}

.shop-single .product-detail .product-price-rating .product-price h4 span {
  color: #F3601E;
  margin-left: 10px;
}

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0;
}

.shop-single .product-detail .product-price-rating .product-rating i {
  color: #F3601E;
}

.shop-single .product-detail .add-to-cart-input .form-control {
  height: 54px;
}

.shop-single .product-detail .product-summary {
  margin-top: 30px;
}

.shop-single .product-detail .product-summary a {
  margin-right: 10px;
  color: #F3601E;
}

.shop-single .product-detail .product-summary a i {
  color: #F3601E;
  margin-right: 10px;
}

.shop-single .product-detail .product-summary a:hover {
  color: #121212;
}

.shop-single .product-detail hr {
  margin: 35px 0;
}

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 35px;
}

.shop-single .product-detail .product-detail-meta span {
  display: block;
  margin: 15px 0;
}

.shop-single .product-detail .product-detail-meta span strong {
  color: #121212;
  font-weight: 500;
}

.shop-single .product-detail .product-detail-meta span a {
  color: #7c8087;
}

.shop-single .product-detail .product-detail-meta span a:hover {
  color: #F3601E;
}

.shop-single .product-detail .product-detail-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 35px 0;
}

.shop-single .product-detail .product-detail-social span {
  font-weight: 500;
  color: #121212;
}

.shop-single .product-detail .product-detail-social ul {
  padding-left: 15px;
  margin-bottom: 0;
}

.shop-single .product-detail .product-detail-social ul li {
  display: inline-block;
  list-style-type: none;
  margin-right: 20px;
  font-size: 16px;
}

.shop-single .product-detail .product-detail-social ul li a {
  color: #F3601E;
}

.shop-single .product-detail .product-detail-social ul li a:hover {
  color: #121212;
}

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px;
}

.shop-single .product-detail .wishlist-icon {
  font-size: 22px;
  margin-left: 20px;
}

.slider-slick .slider-nav .slick-next:before {
  margin: 0 auto;
  text-align: center;
  content: "\f178";
  font-family: "Font Awesome 6 Free";
  z-index: 999;
  color: #121212;
  font-size: 20px;
}

.slider-slick .slider-nav .slick-prev:before {
  margin: 0 auto;
  text-align: center;
  content: "\f177";
  font-family: "Font Awesome 6 Free";
  z-index: 999;
  color: #121212;
  font-size: 20px;
}

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1;
}

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1;
}

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
}

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
}

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px;
}

.owl-theme2 .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-theme2 .owl-nav .owl-prev {
  position: absolute;
  left: -5px;
  z-index: 5;
}

.owl-theme2 .owl-nav .owl-next {
  position: absolute;
  right: -5px;
  z-index: 5;
}


/***********************
      About Page 
************************/

.about-img img {
  border: 15px solid #fff;
  box-shadow: 0 5px 20px 0 rgba(18, 18, 18, 0.07);
}

.founder-text{
  color: #121212;
}

.bussiness-location {
  /* background: rgba(243, 96, 30, 0.08); */
  box-shadow: 0 5px 20px 0 rgba(18, 18, 18, 0.07);
  padding: 30px;
  position: relative;
  display: flex;
}

.bussiness-location h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
/* Hide the popup by default */
.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup img {
  max-width: 80%;
  max-height: 80%;
  display: block;
  margin: 0 auto;
}

.close {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.feature-info-icon {
  background: rgba(243, 96, 30, 0.2);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.feature-info-icon i {
  font-size: 34px;
  color: #F3601E;
}

.bussiness-location::before {
  position: absolute;
  content: "";
  left: 0;
  width: 6px;
  height: 100%;
  background: #f3601e;
  top: 0;
}

.about-video iframe {
  height: 600px;
  width: 100%;
  margin-bottom: -200px;
  position: relative;
}

.our-team-section {
  padding-top: 280px;
}

/****************************
        team
****************************/

.team {
  margin-bottom: 80px;
}

.team .team-info-wrapper {
  background-color: #f9f5f0;
}

.team .team-image {
  position: relative;
}

.team .team-image img {
  border: 15px solid #fff;
  box-shadow: 0px 7px 16px 0px rgba(18, 18, 18, 0.07);
}

.team .team-social {
  cursor: pointer;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 15px 0 25px;
}

.team .team-social ul {
  position: relative;
}

.team .team-social ul li a {
  margin: 0px 5px;
  background: #121212;
  color: #fff;
  padding: 10px;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.team .team-social ul li a:hover {
  background: #F3601E;
  color: #ffffff;
}


.team .team-detail {
  padding: 80px
}

.team .team-detail.team-detail-img-right {
  padding-right: 200px;
}

.team .team-detail.team-detail-img-left {
  padding-left: 200px;
}

.team .team-detail .team-label {
  padding-bottom: 10px;
  display: block;
  font-weight: 600;
  color: #424344;
  text-transform: uppercase;
}

.team .team-detail .team-title {
  font-size: 28px;
  font-weight: 700;
  color: #121212;
}

.team-img-wrapper {
  position: absolute;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

/**********************************
      Gallery box
***********************************/

.gallery-box {
  position: relative;
  overflow: hidden;
}

.gallery-box:hover .gallery-info .popup-icon {
  opacity: 1;
  top: 50%;
  visibility: visible;
  height: 100%;
  width: 100%;
}

.gallery-box .gallery-info .popup-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  margin: 0 auto;
  top: 40%;
  opacity: 0;
  visibility: hidden;
  right: 0;
  left: 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.gallery-box:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.gallery-box img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: -webkit-transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.gallery-images {
  position: relative;
}

.gallery-images::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(7, 28, 31, 0.0);
  visibility: hidden;
}

.gallery-images:hover:after {
  background: rgba(7, 28, 31, 0.5);
  visibility: visible;
}

ul.list li i {
  margin-right: 10px;
  color: #F3601E;
}

/*contact us*/
.contact-info li i {
  margin-right: 20px;
  color: #ffffff;
  font-size: 30px;
}

.social-info h6 {
  padding: 30px 0;
  font-weight: 600;
}

.social-info .contact-info ul li {
  padding-bottom: 25px;
}

.social-info .contact-info ul li img {
  height: 36px;
  width: 36px;
  color: #ffffff;
}

.social-info .social-icon {
  margin-top: 10px;
}

.social-icon ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}

.social-icon ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style-type: none;
  float: left;
}

.social-info .social-icon ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  color: #fff;
  margin-right: 25px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.social-info .social-icon ul li a:hover {
  color: #121212;
}

.message-info {
  padding: 35px;
  border-top-left-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

.contact-form {
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}

.form-inline {
  position: relative;
}

.form-inline .btn {
  /* position: absolute; */
  top: 0;
  right: 0;
  padding: 14px 40px 14px;
  height: 59px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/****** pagination ****/

.pagination {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pagination .page-item {
  margin: 0 5px 5px;
}

.pagination .page-item .page-link {
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #121212;
  background-color: transparent;
  border: 2px solid #ededed;
  border-radius: 0px;
}

.pagination .page-item .page-link span {
  font-size: 26px;
}

.pagination .page-item .page-link:hover {
  color: #F3601E;
  background-color: transparent;
  border: 2px solid #F3601E;
}

.pagination .page-item .page-link:hover:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination .page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.pagination .page-item.active .page-link {
  color: #F3601E;
  background-color: transparent;
  border: 2px solid #F3601E;
}

/************ nav tab *****************/

.nav-pills .nav-link {
  padding: 12px 15px;
  color: #121212;
  background-color: #ededed;
  margin-bottom: 10px;
}

.nav-pills .nav-link:hover {
  color: #fff;
  background-color: #f3601e;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #f3601e;
}

.feature-box {
  /* background-color: #ededed; */
  border: 1px solid #ededed;
  text-align: center;
}

.feature-box:hover {
  background-color: #F3601E;
  text-align: center;
}

.feature-box a {
  padding: 20px;
  display: block;
  text-align: center;
}

.feature-box i {
  font-size: 36px;
  color: #7c8087;
}

.feature-box .feature-title {
  font-weight: 500;
}

.feature-box:hover i {
  color: #fff;
}

.feature-box:hover .feature-title {
  color: #fff;
}

/**********************************
      responsive
***********************************/

@media (max-width: 1400px) {
  .header.header-3 .navbar {
    padding: 0 30px;
  }

  .header.header-3 .navbar .navbar-nav .nav-item {
    margin-right: 12px;
  }

  .header.header-3 .topbar {
    padding: 0 30px;
  }
}

@media (max-width: 1199px) {
  .header.header-3 .topbar {
    padding: 0;
  }

  .header.header-3 .navbar {
    padding: 0;
  }

  .header.header-3 .navbar .navbar-nav .nav-item {
    margin-right: 12px;
  }

  .header .navbar .navbar-nav .nav-item {
    margin-right: 15px;
  }

  .header.sign-header {
    background-color: #F3601E !important;
  }

  .header.sign-header .navbar-brand img {
    width: 210px;
  }

  .featured-categories-column .categories-title {
    font-size: 16px;
  }

  /* .testimonial-dark-bg .testimonial-description {
    padding: 50px 70px 50px 70px;
  } */

  .testimonial-dark-bg .testimonial-description .testimonial .testimonial-content p {
    color: #ffffff;
    font-size: 18px;
    line-height: 34px;
  }

  .blog-post .blog-info h4 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    max-height: 460px;
    overflow: auto;
    left: 0;
    margin-top: -1px;
    margin-left: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 16px 0px rgba(18, 18, 18, 0.07);
  }

  .header .navbar .navbar-nav {
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: initial;
  }

  .header .navbar .navbar-nav .nav-link {
    color: #121212;
    padding: 10px 20px;
  }

  .header .navbar .navbar-nav .nav-link i {
    position: absolute;
    right: 20px;
  }

  .header .navbar .navbar-nav .nav-item {
    margin-right: 0;
  }

  .header .navbar .navbar-nav .dropdown.mega-menu .megamenu {
    width: 95%;
    margin: 0 auto;
  }

  .header .navbar .dropdown-menu {
    margin: 0px 15px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f3f9ff;
    padding: 15px;
    font-size: 13px;
    border-radius: 6px;
  }

  .header .navbar .dropdown-menu .dropdown-item {
    padding: 10px 0px;
  }

  .header .navbar .nav-title {
    margin-top: 20px;
  }

  .header .navbar-toggler {
    /* position: absolute;
    right: 15px; */
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    background: #F3601E;
    color: #ffffff;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 42px;
    width: 42px;
    border-radius: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header .navbar .add-listing {
    padding-right: 15px;
  }

  .header .navbar .add-listing .menu-btn {
    display: none;
  }
  .header .container {
    max-width: 100%;
  }

  .topbar-call.topbar-divider::after {
    display: none;
  }

  .header .navbar-brand {
    max-width: 200px;
  }

  .add-listing .account-icon {
    /* width: 42px; */
    justify-content: center;
  }

  .add-listing {
    padding-right: 15px;
  }
   
  .about-video iframe {
    height: 400px;
    margin-bottom: -120px;
  }

  .our-team-section {
    padding-top: 200px;
  }
  
/* .header .navbar {
  position: absolute;
  right: 0;
  top: 77px;
} */
/* Default state (desktop view) */
.mobile-only {
  display: none;
}

/* Mobile view */
@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }
}
  .account-action {
    margin-left: 10px;
  }

  .woo-action .woo-action-icon {
    margin-left: 10px;
  }

  .search-form .form-control {
    min-width: 220px;
  }

  .feature-categories-wrapper {
    margin: 0 -12px;
    flex-wrap: wrap;
  }

  .featured-categories-column {
    width: 33.33%;
    padding: 0 12px;
    margin-bottom: 20px;
  }

}

@media (max-width: 767px) {
  .header .navbar-brand {
    max-width: 180px;
  }
  .header .topbar-inner .topbar-divider::after {
    display: none;
  }
  .section-title .storke-title {
    font-size: 60px;
    top: -18px;
  }
  /* .testimonial-dark-bg .testimonial-description {
    padding: 30px 50px 30px 50px;
  } */

  .testimonial .testimonial-author {
    margin-top: 15px;
  }

  .cart-table .actions {
    display: block;
    padding: 20px 15px;
  }

  /*** team ****/
  .team {
    margin-bottom: 60px;
  }

  .team-img-wrapper {
    position: relative;
  }

  .team .team-detail.team-detail-img-right {
    padding-right: 50px;
  }

  .team .team-detail.team-detail-img-left {
    padding-left: 50px;
  }

  .team .team-detail {
    padding: 80px 50px 50px;
  }
  .about-video iframe {
    height: 250px;
    margin-bottom: -60px;
  }

  .our-team-section {
    padding-top: 140px;
  }
}

@media (max-width: 576px) {

  .header .navbar-brand {
    max-width: 150px;
  }

  /* .header .navbar-toggler {
    padding: 10px;
    width: 42px;
    height: 42px;
  } */

  .account-action {
    /* display: none; */
  }

  .search-action.woo-action-icon {
      display: none;
  }

  .header .topbar-inner .topbar-divider::after {
    display: none;
  }
  .section-title .storke-title {
    font-size: 46px;
    top: -18px;
  }
  .featured-categories-column {
    width: 50%;
  }

  /* .testimonial-dark-bg .testimonial-description {
    padding: 30px 30px 30px 30px;
  } */

  .banner-content h5 {
    font-size: 40px;
  }

  .banner-content h2 {
    font-size: 28px;
  }

  .commentlist {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .commentlist .comment-content {
    margin-left: 0px;
    margin-top: 15px;
  }

  .commentlist .comment-content .reviews .rating {
    margin-left: inherit;
    margin-bottom: 15px;
  }

  .commentlist .comment-content .reviews {
    flex-wrap: wrap;
  }

  .cart-table .actions .coupon form {
    display: block;
  }

  .cart-table .actions .coupon form .input-text {
    width: 100%;
  }

  .cart-table .actions .coupon #coupon_code {
    margin-bottom: 10px;
  }

}

@media (max-width: 479px) {
  .header .navbar-brand {
    max-width: 138px;
  }

  .furniture-collection .furniture-info {
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .furniture-title {
    font-size: 20px;
    margin-bottom: 10px !important;
  }
  .add-listing .woo-action .cart .dropdown-menu-right {
    width: 280px;
    right: inherit;
    left: -200px;
  }
  .add-listing .account-icon {
    padding: 0 10px;
    width: 42px;
  }
  .add-listing .account-icon i {
    font-size: 16px;
  }
  .add-listing {
    padding-right: 10px;
  }
  .add-listing .account-icon span {
    display: none;
  }
  .login-register-modal .modal-body {
    padding: 15px;
  }
  .login-register-modal form {
    padding: 40px 20px 20px;
  }
  .about-video iframe {
    height: auto;
  }
  .our-team-section {
    padding-top: 120px;
  }
}